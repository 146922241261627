import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import NonStretchedImage from '../../components/layout/NonStretchedImage';
import ContactForm from '../../components/forms/ContactForm';
import GetStarted from '../../components/cta/GetStarted';

import { ViewportBreakpoints } from '../../components/cssConstants';

import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import Margin from '@rotaready/frecl/build/Margin';
import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import Separator from '@rotaready/frecl/build/Separator';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const isBrowser = typeof window !== 'undefined';

const HeroWrapper = styled.div`
  background-repeat: no-repeat;

  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(${props => props.backgroundMobile});

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    background-position: right top;
    background-size: auto 100%;
    background-image: url(${props => props.backgroundDesktop});
    transform: translate3d(0, -80px, 0);
  }
`;

const Hero = styled.div`
  padding: 80px 0 470px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 210px 0 130px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 40%;
  }
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LearnMoreSection = styled.div`
  text-align: center;
  max-width: 640px;
  margin: 0 auto;
  padding: 40px 0 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 80px 0 40px 0;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const TileTagContainer = styled.div`
  margin-bottom: 20px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const BenefitBlock = styled.div`
  padding: 40px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const StatBreakerBar = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grey10};
`;

const StatBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    padding: 50px 0;
  }
`;

const StatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 40px 0;
  height: 180px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0 100px;
    height: 200px;
  }
`;

const StatHeading = styled(H2).attrs({
  uistyle: 'success',
})``;

const StatLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ComparisonTableSection = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 60px 0 0 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 100px 0 0 0;
  }
`;

const ComparisonTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const ComparisonRow = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    flex: 1 1 auto;
    padding: 10px 0;
  }

  > div:nth-child(1) {
    text-align: left;
    width: 100%;
  }

  > div:nth-child(2),
  > div:nth-child(3) {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50px;
  }

  > div:nth-child(2) {
    background-color: ${({ theme: { colors } }) => colors.grey10};
  }

  > div:nth-child(3) {
    background-color: ${({ theme: { colors } }) => colors.brand10};
  }

  &:first-child {
    top: 0;
    position: sticky;

    > div {
      padding: 10px;
      width: 200px;
    }
  }

  &:not(:last-child):not(:first-child) {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.grey40};
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0 10px;
      padding: 20px 40px;
    }

    > div:nth-child(1) {
      margin: 0 10px 0 0;
      width: 33.3%;
    }

    > div:nth-child(2),
    > div:nth-child(3) {
      width: 33.3%;
    }

    > div:nth-child(2) {
      margin: 0 10px;
    }

    > div:nth-child(3) {
      margin: 0 0 0 10px;
    }
  }
`;

const CheckmarkIconRaw = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'check'],
})`
  color: ${({ theme: { colors } }) => colors.success};
`;

const TimesIconRaw = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'times'],
})`
  color: ${({ theme: { colors } }) => colors.alert};
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.formTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.formTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Free Excel rota template - Rotaready"
          description="Build simple employee schedules with our free rota template. A more intelligent way for hospitality, retail and leisure businesses to build staff rotas using Excel."
          url="free-excel-rota-template"
        />

        <HeroWrapper
          backgroundDesktop={data.heroBackgroundDesktop.childImageSharp.fixed.src}
          backgroundMobile={data.heroBackgroundMobile.childImageSharp.fixed.src}
        >
          <ResponsiveContainer>
            <Hero>
              <HeroInnerContainer>
                <HeroBody>
                  <TileTagContainer>
                    <TileTag text="Hospitality" />
                    <TileTag text="Retail" />
                    <TileTag text="Leisure" />
                  </TileTagContainer>

                  <HeroTextWrapper>
                    <H1 uistyle="brand160" text="Free Excel rota template" />
                  </HeroTextWrapper>

                  <HeroTextWrapper>
                    <H4 uistyle="grey">Plan simple staff rotas with our free rota template</H4>
                  </HeroTextWrapper>

                  <Margin marginTop={40}>
                    <div>
                      <Button uistyle="primary" text="Download the rota template" size="lg" onClick={this.goToForm} />
                    </div>
                  </Margin>
                </HeroBody>
              </HeroInnerContainer>
            </Hero>
          </ResponsiveContainer>
        </HeroWrapper>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="Looking for a smarter way to schedule staff rotas? You've come to the right place." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="With our free Excel rota template you can:" />
              </TextBlock>

              <BenefitBlock>
                <TextBlock>
                  <UL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="Build staff rotas tailored to your business" />
                    <TextListItem text="Store your budget & forecast costs per week (hours, wages, sales, wage %)" />
                    <TextListItem text="Easily view hours & costs across the rota - per day total or per employee" />
                    <TextListItem text="Automatically validate if shifts are too long, employees have sufficient days off and that contractual hours have been met" />
                    <TextListItem text="Monitor number of employees working per hour" />
                  </UL>
                </TextBlock>
              </BenefitBlock>
            </BodyItem>

            <BodyItem innerRef={this.formTopRef}>
              <ContactForm
                title=""
                submitLabel="Download the Excel template"
                source="resources_free_excel_rota_template"
                label="rotaready.com/free-excel-rota-template"
                marketoProgramName="FY25-P04-HOS-CDP-MOF-Resource-Excel-free-template-Rotaready"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Enjoy 🎉" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading our free Excel rota template." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/Rotaready-Excel-Template.xlsx" download>
                        <Button uistyle="primary" text="Download now" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </BodyItem>
          </BodyWrapper>

          <Separator />

          <LearnMoreSection>
            <TextBlock>
              <H2 uistyle="brand160" text="Building the perfect rota" />
            </TextBlock>

            <TextBlock>
              <Text size="xl" text="Hold-up... did you know there's something much better you can use to build the perfect rota template and monitor staff hours?" />
            </TextBlock>

            <TextBlock>
              <Text size="xl" text="Not only is it faster and easier to build your staff rotas, but it comes with a whole host of features and tools that businesses like yours simply can't live without. Still unconvinced? Just ask our customers:" />
            </TextBlock>
          </LearnMoreSection>
        </ResponsiveContainer>

        <StatBreakerBar>
          <ResponsiveContainer>
            <StatBoxWrapper>
              <StatBox>
                <StatHeading text="562% ROI" />
                <StatLogo>
                  <NonStretchedImage fixed={data.o2Logo.childImageSharp.fixed} />
                </StatLogo>
                <Link to="/customer-stories/retail-o2-bak">
                  <Button uistyle="primary" text="Read the full story" size="md" ghost />
                </Link>
              </StatBox>

              <StatBox>
                <StatHeading text="676% ROI" />
                <StatLogo>
                  <NonStretchedImage fixed={data.markSargeantLogo.childImageSharp.fixed} />
                </StatLogo>
                <Link to="/customer-stories/hospitality-mark-sargeant">
                  <Button uistyle="primary" text="Read the full story" size="md" ghost />
                </Link>
              </StatBox>

              <StatBox>
                <StatHeading text="577% ROI" />
                <StatLogo>
                  <NonStretchedImage fixed={data.glgLogo.childImageSharp.fixed} />
                </StatLogo>
                <Link to="/customer-stories/hospitality-golden-lion-group">
                  <Button uistyle="primary" text="Read the full story" size="md" ghost />
                </Link>
              </StatBox>
            </StatBoxWrapper>
          </ResponsiveContainer>
        </StatBreakerBar>

        <ResponsiveContainer>
          <ComparisonTableSection>
            <TextBlock>
              <H2 uistyle="brand160" text="And of course it does far more" />
            </TextBlock>

            <ComparisonTable>
              <ComparisonRow>
                <div>
                  &nbsp;
                </div>
                <div>
                  <H3 uistyle="brand160" text="Excel template" />
                </div>
                <div>
                  <H3 uistyle="brand160" text="Rotaready" />
                </div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Easily shareable" size="xl" /></div>
                <div><CheckmarkIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Visibility of upcoming costs & shifts" size="xl" /></div>
                <div><CheckmarkIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Customisable settings e.g. staff groups, names, shift types & opening times" size="xl" /></div>
                <div><CheckmarkIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Staffing chart" size="xl" /></div>
                <div><CheckmarkIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Automatic validation of contractual hours" size="xl" /></div>
                <div><CheckmarkIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Automatic rota building" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="iOS & Android mobile apps" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Shift swaps" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Working time compliance" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Cost control" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Sales forecasts (AI-driven)" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="See upcoming reservations" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Local weather" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Accommodates for salaried staff" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Overtime rules" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Split shifts" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Cross-site cover shifts" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Accurate social taxes" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Shift broadcasting" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Document storage" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Time & attendance monitoring" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>

              <ComparisonRow>
                <div><Text text="Record & track notable events" size="xl" /></div>
                <div><TimesIconRaw /></div>
                <div><CheckmarkIconRaw /></div>
              </ComparisonRow>
            </ComparisonTable>
          </ComparisonTableSection>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackgroundDesktop: file(relativePath: { eq: "resources/free-excel-rota-template/hero-desktop.png" }) {
      childImageSharp {
        fixed(height: 1400, width: 2132, quality: 30) {
          src
        }
      }
    }

    heroBackgroundMobile: file(relativePath: { eq: "resources/free-excel-rota-template/hero-mobile.png" }) {
      childImageSharp {
        fixed(height: 896, width: 750, quality: 30) {
          src
        }
      }
    }

    o2Logo: file(relativePath: { eq: "client-logos/o2.png" }) {
      childImageSharp {
        fixed(height: 50, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    markSargeantLogo: file(relativePath: { eq: "client-logos/mark-sargeant.png" }) {
      childImageSharp {
        fixed(height: 50, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    glgLogo: file(relativePath: { eq: "client-logos/golden-lion-group.png" }) {
      childImageSharp {
        fixed(height: 50, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
